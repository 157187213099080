body, html{
  height:100%;
  margin:0;
  font-size:16px;
  font-family: 'Open Sans', sans-serif;
  font-weight:400;
  line-height:1.8em;
  color:#ffffff;
}

.center-content{
  display: block;
  margin-left:
  auto;margin-right: auto;
}

.navbar-default{
  transition:400ms ease;
  background:transparent;
}

.navbar-default.scrolled{
  background:#132b47;
}

/* Nav underline stroke */
nav ul li a, nav ul li a:after, nav ul li a:before {
  transition: all .33s;
}

nav.stroke ul li a{
  position: relative;
}
nav.stroke ul li a:after{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #f4f4f4;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #292b2c;
}

.home-background, .tech-background, .approach-background, .contact-background, .review-background{
  position:relative;
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  background-attachment:fixed;
}

.home-background{
  background-image:url('../img/backgrounds/home_bg.png');
  min-height:90vh;
}

.tech-background{
  background-image: linear-gradient(to right top, #132b47, #132b47, #132b47, #132b47, #132b47, #0c3057, #043466, #003876, #004099, #0047bc, #004bde, #334cff);
  min-height:70vh;
  padding-bottom: 5vh;
}

.review-background{
  background-image: linear-gradient(to right top, #132b47, #132b47, #132b47, #132b47, #132b47, #0c3057, #043466, #003876, #004099, #0047bc, #004bde, #334cff);
}

.approach-background{
  background-color: #FFFFFF;
}

.contact-background{
    background-image: linear-gradient(to left bottom, #132b47, #132b47, #132b47, #132b47, #132b47, #0c3057, #043466, #003876, #004099, #0047bc, #004bde, #334cff);
}

.section{
  text-align:center;
  height: 30vh;
}

.section-slim{
  text-align:center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 5vw;
  padding-right: 5vw;
}

.about-section{
  background-color:#ffffff;
  color:#666;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.section-dark{
  background-color:#282e34;
  color:#ddd;
}

.ptext{
  position:absolute;
  top:50%;
  width:100%;
  text-align:center;
  color:#fff;
  font-size:27px;
  letter-spacing:5px;
  text-transform:uppercase;
}

.ptext .border{
  background-color:#111;
  color:#fff;
  padding:20px;
}

.ptext .border.trans{
  background-color:transparent;
}

.approach-container{
  min-height: 70vh;
}

.approach-img{
  max-width: 10vw;
}

@media (max-width: 860px) {
  .flexbox {
    height: 220vw;
  }
  .flexbox .item {
    width: 50%;
  }
}
@media (max-width: 667px) {
  .flexbox {
    height: auto;
  }
  .flexbox .item {
    width: 100%;
  }
}

@media(max-width:568px){
  .home-background, .tech-background, .approach-background{
    background-attachment:scroll;
  }
  #home_statement{
    font-size:15px;
  }
  .home-background .container{
    width: 100vw;
  }
  .navbar-nav a img{
    padding-top: 5px;
    width: 75%;
    height: auto;
  }
}
